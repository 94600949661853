import { getAuth } from "firebase/auth";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import Container from "../components/Container";
import { Spinner } from "../components/Loading";
import OrderItem from "../components/OrderItem";
import { db } from "../firebase/firebase";
import { Order, User } from "../types";
import Button from "../components/Button";
import { X } from "react-feather";

const Admin = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {

  const auth = getAuth();

  const [orders, setOrders] = useState<Order[]>([])
  const [isBusy, setIsBusy] = useState(false)
  const [searchFilter, setSearchFilter] = useState<string>("userId")
  const [searchValue, setSearchValue] = useState<string>()

  const getOrders = async (filter?: string, value?: string) => {
    if (auth.currentUser && user && user.isAdmin) {

      setIsBusy(true)

      const q = (filter && value) ? query(collection(db, "orders"), where(filter, "==", value), orderBy("date", "desc")) : query(collection(db, "orders"), orderBy("date", "desc"))
      const querySnapshotOrders = await getDocs(q);

      const o: Order[] = []
      querySnapshotOrders.forEach(order => {
        o.push(order.data() as Order)

      })
      setOrders(o)
      setIsBusy(false)
    } else console.log("not working")
  }

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line
  }, [auth.currentUser])

  useEffect(() => {
    console.log(searchFilter, searchValue)
  }, [searchFilter, searchValue])

  return (
    <>
      <Container size="small">
        <div className="flex gap-1">
          <select onChange={e => setSearchFilter(e.target.value)}>
            <option value="userId">User ID</option>
            <option value="id">Order ID</option>
            <option value="userName">Name</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
          <div className="relative flex items-center flex-1">
            <input type="text" value={searchValue} onChange={e => setSearchValue(e.target.value)} className="h-full !pr-6" />
            <button onClick={() => setSearchValue("")} className="absolute right-1"><X size={16} /></button>
          </div>
          <Button onClick={() => getOrders(searchFilter, searchValue)}>Search</Button>
        </div>
      </Container>
      <Container size="small">
        <div className="flex flex-col gap-2">
          {orders ? orders?.map(order => {
            return (
              <OrderItem order={order} key={order.id} />
            )
          }) : <div className="">No Orders</div>}
        </div>

        {isBusy && (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner />
          </div>
        )}
      </Container>
    </>
  )
}

export default Admin