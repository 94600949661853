import { StorageReference, deleteObject, getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import Button, { IconButton } from "./Button";
import { Spinner } from "./Loading";
import Overlay from "./Overlay";

interface GalleryImage {
  src: string
  ref: StorageReference
}

const ImageGallery = ({
  setImage,
  setImageName,
}: {
  setImage: React.Dispatch<any>
  setImageName: React.Dispatch<string>
}) => {

  const [isBusy, setIsBusy] = useState(false)

  const [images, setImages] = useState<GalleryImage[]>([])

  const getAllImages = async () => {
    setIsBusy(true)
    const storage = getStorage();
    const listRef = ref(storage, 'donuts');

    try {
      const res = await listAll(listRef);
      const newImages = await Promise.all(res.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return {
          src: url,
          ref: itemRef
        };
      }));

      setImages(newImages);
      setIsBusy(false)
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (ref: StorageReference) => {
    setIsBusy(true)

    deleteObject(ref).then(() => {
      console.log("Deleted file")
      setIsBusy(false)
      getAllImages()
    }).catch((error) => console.log(error));
  }

  const handlePick = async (image: GalleryImage) => {
    setIsBusy(true)
    const storage = getStorage();
    getDownloadURL(ref(storage, image.ref.fullPath))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          setImage(xhr.response);
          console.log(image.ref.name)
          setImageName(image.ref.name)
          console.log(xhr.response)
          setIsBusy(false)
        };
        xhr.open('GET', url);
        xhr.send();

      })
      .catch((error) => {
        // Handle any errors
      });
  }

  useEffect(() => {
    getAllImages()
  }, [])

  return (
    <Overlay trigger={<Button>Gallery</Button>}>
      <div className="grid grid-cols-3 gap-2 relative">
        {images?.map((image, i) => {
          return (
            <div key={i} className="w-full border border-gray-200 rounded flex flex-col items-center cursor-pointer hover:shadow-lg transition-shadow duration-500" onClick={() => handlePick(image)}>
              <img src={image.src} alt="" className="w-24 h-24" />
              <div className="p-2 flex flex-col gap-2">
                <div className="text-xs">{image.ref.name}</div>
                <div className="flex gap-4 justify-end w-full">
                  <IconButton Icon={Trash2} onClick={() => handleDelete(image.ref)} />
                </div>
              </div>
            </div>
          )
        })}
        {isBusy && <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center min-h-24">
          <Spinner />
        </div>}
      </div>
    </Overlay>
  )
}

export default ImageGallery