// App.tsx
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Signup from "./components/Signup";
import { db } from "./firebase/firebase";
import Admin from "./pages/Admin";
import Checkout from "./pages/Checkout";
import Donuts from "./pages/Donuts";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import UserProfile from "./pages/UserProfile";
import { User, defaultUser } from "./types";

function App() {

  const storedUser: User = localStorage.getItem("donutelloUser")
    ? JSON.parse(localStorage.getItem("donutelloUser")!)
    : defaultUser;

  const [user, setUser] = useState<User>(storedUser);

  useEffect(() => {
    localStorage.setItem("donutelloUser", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    handleUpdateUser();
    // eslint-disable-next-line
  }, [user]);

  const handleUpdateUser = async () => {
    const auth = getAuth();

    if (auth.currentUser) {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        await updateDoc(userDocRef, {
          cart: user.cart,
        });
      } else {
        console.warn("User document does not exist. Skipping update.");
      }
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const docRef = doc(db, "users", authUser.uid);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          setUser(docSnapshot.data() as User);
        }
      } else {
        setUser(storedUser);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header user={user} setUser={setUser} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donuts" element={<Donuts user={user} setUser={setUser} />} />
        <Route path="/checkout" element={<Checkout user={user} setUser={setUser} />} />
        <Route path="/user" element={<UserProfile user={user} setUser={setUser} />} />
        <Route path="/signup" element={<Signup user={user} setUser={setUser} />} />
        <Route path="/admin" element={<Admin user={user} setUser={setUser} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <div id="overlays" />
    </>
  );
}

export default App;
