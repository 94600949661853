import { getAuth } from "firebase/auth";
import { collection, getCountFromServer, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import Container from "../components/Container";
import { Spinner } from "../components/Loading";
import OrderItem from "../components/OrderItem";
import { db } from "../firebase/firebase";
import { Order, User } from "../types";

const UserProfile = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {

  const [orderCount, setOrderCount] = useState(0)

  const [orders, setOrders] = useState<Order[]>([])
  const [isBusy, setIsBusy] = useState(false)

  const getOrders = async () => {

    const auth = getAuth();
    if (auth.currentUser && user && user.id) {
      console.log("has user")

      setIsBusy(true)
      const q = query(collection(db, `orders`), where("userId", "==", user.id), orderBy("date", "desc"));

      const querySnapshot = await getDocs(q);
      const o: Order[] = []
      querySnapshot.forEach((doc) => {
        o.push(doc.data() as Order)
      });
      setOrders(o)
      setIsBusy(false)
    } else console.log("no user")
  }

  const getOrderCount = async () => {
    const q = query(collection(db, `orders`), where("userId", "==", user.id), orderBy("date", "desc"));
    const count = await getCountFromServer(q);
    setOrderCount(count.data().count)
  }

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged(currentUser => {
      getOrders()
      getOrderCount()
    })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Container size="small">
        <div className="flex flex-col gap-2">
          <InfoRow title="Name" info={user.name} />
          <InfoRow title="Email" info={user.email} />
          <InfoRow title="Orders" info={orderCount.toString()} />
        </div>
      </Container>
      <Container size="small" noBackground>
        <div className="flex flex-col gap-4">
          {orders ? orders?.map((order, i) => {
            return (
              <OrderItem key={i} order={order} />
            )
          }) : <div className="">No Orders</div>}
        </div>

        {isBusy && (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner />
          </div>
        )}
      </Container>
    </>
  )
}

export default UserProfile

const InfoRow = ({ title, info }: { title: string, info?: string }) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm text-gray-600">{title}</p>
      <p>{info || "-"}</p>
    </div>
  )
}