import Logo from "../assets/logo.png"

export const Spinner = ({
  size = "normal"
}: {
  size?: "normal" | "big"
}) => {
  return (
    <span className={`loader ${size === "big" ? "big" : ""}`}></span>
  )
}

export const Loading = ({
  size = "normal"
}: {
  size?: "normal" | "big"
}) => {
  return (
    <div className="fixed w-full h-full top-0 left-0 bg-surface flex items-center justify-center z-20">
      <div className="absolute inline-flex justify-center flex-col items-center">
        <img src={Logo} alt="Donutello Donuts Logo" className={`bouncing w-auto ${size === "big" ? "h-16" : "h-8"}`} />
        <div className="loading-shadow" />
        <div className=" font-handwriting text-xl mt-2">loading …</div>
      </div>
    </div>
  )
}

