import { HTMLInputTypeAttribute, ReactNode } from "react"

const Input = ({
  type = "text",
  value,
  label,
  onChange,
}: {
  type?: HTMLInputTypeAttribute
  value: string
  label: string
  onChange: (e: any) => void
}) => {
  return (
    <label className="w-full flex flex-col gap-0.5">
      <div className="text-xs text-gray-500">{label}</div>
      <input type={type} value={value} onChange={onChange} />
    </label>
  )
}

export default Input

export const InputGroup = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col sm:flex-row gap-2 w-full">
    {children}
  </div>
)