import { Timestamp, addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState } from "react";
import Button from "../components/Button";
import ProgressBar from "../components/ProgressBar";
import { db, storage } from "../firebase/firebase";
import { Donut, User } from "../types";

import { Plus, Upload as UploadIcon } from "react-feather";
import { resizeFile } from "../helpers/imageHelper";
import InputSection from "./InputSection";
import Overlay from "./Overlay";

const Upload = ({ user }: { user: User }) => {

  const [isUploading, setIsUploading] = useState(false)

  const [image, setImage] = useState<any>()

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(16)

  const [progress, setProgress] = useState(0);

  const handleChange = async (event: any) => {
    try {
      const file = await resizeFile(event.target.files[0], 300)
      setImage(file);
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpload = () => {
    if (!image) {
      alert("Please upload an image first!")
      return
    }

    setIsUploading(true)

    const storageRef = ref(storage, `/donuts/${image.name}`)

    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on("state_changed", (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
      setProgress(percent)
    },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {

          await addDoc(collection(db, "donuts"), {
            imageUrl: url,
            imageName: image.name,
            storageRef: `/donuts/${image.name}`,
            lastModified: Timestamp.fromMillis(image.lastModified),
            name: name,
            description: description,
            price: price,
            quantity: quantity,
            fileName: image.name,
          } as Donut).then(() => {
            setName("")
            setImage(null)
            setDescription("")
            setPrice(1)
            setQuantity(16)
            setProgress(0)
            setIsUploading(false)
          });
        });
      }
    )
  };

  return (
    <>
      {user?.isAdmin && <Overlay title="New Donut" isBusy={isUploading} trigger={<div className="w-full h-full min-h-48 cursor-pointer bg-primary rounded text-white flex items-center justify-center hover:shadow-lg transition-shadow duration-500"><Plus size={24} /></div>}>
        <div className='flex flex-col sm:flex-row gap-4 w-full'>
          <div className='border border-gray-300 rounded p-4 w-full md:w-1/2'>
            <label>
              <div className="w-full bg-primary rounded py-2 px-2 text-center text-sm flex items-center cursor-pointer mb-2 gap-4"><UploadIcon size={16} /> Upload</div>
              <input type="file" onChange={handleChange} accept="/image/*" className="absolute -left-[9999px]" />
            </label>
            {image && <img src={URL.createObjectURL(image)} width={image.width} height={image.height} className="w-full max-w-[300px] h-auto rounded" alt="" />}
          </div>
          <div className='w-full md:w-1/2 border border-gray-200 rounded p-4 flex flex-col justify-start items-start space-y-4 flex-1'>

            <InputSection label="Title">
              <input type="text" name="title" onChange={(e) => setName(e.target.value)} value={name} />
            </InputSection>

            <InputSection label="Price">
              <input type="number" name="price" onChange={(e) => setPrice(Number(e.target.value))} value={price} />
            </InputSection>

            <InputSection label="Quantity">
              <input type="number" name="quantity" onChange={(e) => setQuantity(Number(e.target.value))} value={quantity} />
            </InputSection>

            <InputSection label="Description">
              <textarea name="description" onChange={(e) => setDescription(e.target.value)} value={description}></textarea>
            </InputSection>

            <Button className="w-full" onClick={handleUpload} disabled={!user.isAdmin || !name || !price || !quantity || !image}>Upload</Button>

            <ProgressBar progress={progress} />

          </div>
        </div>
      </Overlay>}
    </>
  );
}

export default Upload