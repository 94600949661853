import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase/firebase";
import { User, defaultCart } from "../types";
import Button from "./Button";
import Input, { InputGroup } from "./Input";
import { createId } from "../helpers/stringHelper";

const SignIn = ({
  setUser,
  user,
}: {
  user: User,
  setUser: React.Dispatch<React.SetStateAction<User>>;
}) => {
  const [email, setEmail] = useState<string>(user.email || "");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState()

  const handleLogin = async () => {
    const auth = getAuth();

    try {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const authUser = userCredential.user;

          // Fetch user data from the database
          const docRef = doc(db, "users", authUser.uid);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            console.log("snapshot exists")
            // Update the user state with fetched data
            setUser(docSnapshot.data() as User);
          } else {
            console.log("snapshot does not exist")
            // If the user document doesn't exist, create a new user
            const newUser: User = {
              id: createId(),
              name: "",
              lastName: "",
              email: authUser.email || "",
              orders: [],
              cart: defaultCart,
            };

            setUser(newUser);
            await setDoc(docRef, newUser);
          }
        })
        .catch((err) => {
          setError(err.message)
        });


    } catch (error) {
      console.error("Login failed:");
      // Handle login failure (display error message, etc.)
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <InputGroup>
        <Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </InputGroup>
      <Button onClick={() => handleLogin()}>Log in</Button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default SignIn;
