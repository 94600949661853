import ColorfulDonutBox from "../assets/colorful-donut-box.jpg";
import HoldingDonut from "../assets/holding-donut.jpg";
import Container from "../components/Container";

const Home = () => {
  return (
    <>
      <Container>
        <HomeBox side="right" title="Welcome to Donutello Donuts!" text="Hey there, sweet tooth! Ready to dive into the delicious world of Donutello Donuts? We're all about tiny treats and big flavors." />
      </Container>
      <div className={`bg-surface bg-cover bg-no-repeat my-8`} style={{}}>
        <div className="flex flex-col max-w-screen-lg mx-auto bg-surface">
          <HomeBox image={HoldingDonut} title="Bite-Sized Bliss" text="Our baby donuts might be small, but trust us, they're bursting with flavor. From classic faves to some seriously creative concoctions, each little donut is packed with a whole lot of yum." />
          <HomeBox side="right" image={ColorfulDonutBox} title="A Donut for Every Mood" text="We've got baby donuts for every vibe – a sprinkle of joy, a swirl of chocolate dreams, or a burst of fruity happiness. Pick a donut that matches your mood and let your taste buds take a joyride." />
        </div>
      </div>
    </>
  );
};

export default Home;

const HomeBox = ({
  title,
  text,
  image,
  side = "left",
}: {
  title: string
  text: string
  image?: string
  side?: "left" | "right"
}) => {
  return (
    <div className={`flex flex-col md:flex-row justify-center items-center`}>
      {image && <div className={`relative w-full md:w-1/2 ${side === "left" ? "md:order-1" : "md:order-2"}`}>
        <img src={image} className={`w-full h-[200px] md:h-[300px] object-cover`} alt="" />
        <div className="top-2 left-2 right-2 bottom-2 absolute border border-white" />
      </div>}
      <div className={`${side === "left" ? "md:order-2" : "md:order-1"} w-full md:w-1/2 p-8 text-center flex flex-col items-center justify-center `}>
        <h3 className="text-2xl after:content-[''] after:w-[100px] after:h-[2px] after:bg-primary after:inline-block after:absolute after:-bottom-2 mb-4 inline-flex justify-center relative">{title}</h3>
        <p className=" max-w-prose">{text}</p>
      </div>
    </div >
  )
}