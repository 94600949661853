import Resizer from "react-image-file-resizer";

export const resizeFile = (file: any, goal: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      goal,
      (file.height * (goal / file.width)),
      "jpg",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    )
  })