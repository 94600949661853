import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from "firebase/auth";


export const logOut = async () => {
  const auth = getAuth();

  try {
    await signOut(auth);
    console.log("Logout successful");
  } catch (error) {
    console.error("Logout failed:");
  }
};

export const login = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
    }).catch((error) => {
      console.log(error.code, error.message)
    });
}