import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ShoppingBag } from 'react-feather'
import { Link } from 'react-router-dom'
import { getBoxesInCartCount } from '../helpers/donutHelper'
import { User } from '../types'
import Button from './Button'
import Cart from './Cart'

const CartPopOver = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {

  return (
    <div className="">
      <Popover className="relative block">
        {({ open }) => (
          <>
            <Popover.Button>
              <div className="relative">
                <ShoppingBag size={18} className='text-text' />
                {getBoxesInCartCount(user) > 0 && (user.cart && user.cart.items) && <div className="absolute -top-1 -right-1 bg-red-500 text-white w-4 h-4 rounded-full text-xs flex items-center justify-center">
                  <span>{getBoxesInCartCount(user)}</span>
                </div>}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute rounded shadow-lg right-0 z-10 mt-3 w-80 p-4 bg-surface">
                <h3 className="mb-2">Cart</h3>
                <Cart user={user} setUser={setUser} />
                {(user.cart && user.cart?.items && user.cart?.items?.length > 0) && <Link to="/checkout"><Button>Checkout</Button></Link>}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default CartPopOver