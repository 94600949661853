import { Transition } from "@headlessui/react";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ArrowLeft, Icon, X } from "react-feather";
import { Spinner } from "./Loading";

const ModalContext = createContext(false)

interface ModalProps {
  children?: ReactNode
  trigger: any
  title?: string | ReactNode
  icon?: Icon
  isBusy?: boolean
}

const Overlay = ({
  children,
  trigger,
  title,
  icon,
  isBusy = false,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isInsideModal, setIsInsideModal] = useState(false)

  const parentModalContext = useContext(ModalContext)

  useEffect(() => {
    setIsInsideModal(parentModalContext)
  }, [parentModalContext])

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") setIsOpen(false)
    })
    document.addEventListener('contextmenu', event => event.preventDefault());
  }, [])

  return (
    <ModalContext.Provider value={true}>
      {React.cloneElement(trigger, { onClick: () => setIsOpen(!isOpen) })}
      {isOpen && document.getElementById("overlays") && createPortal(

        <Transition
          show={isOpen}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-40"
        >
          <div
            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-background bg-noise bg-opacity-95 z-[1] cursor-pointer"
            onClick={() => setIsOpen(false)}
          />
          <div className={`z-[2] w-full max-w-screen-sm border border-gray-300 bg-surface rounded relative p-4`}>
            <div className="flex justify-between mb-2">
              <h3 className="">
                {title}
              </h3>
              <div className="cursor-pointer group" onClick={() => setIsOpen(false)}>
                {isInsideModal ? <ArrowLeft size={16} /> : <X size={16} />}
              </div>
            </div>
            {children}
            {isBusy && <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full bg-white bg-opacity-70 flex items-center justify-center min-h-24 z-20">
              <Spinner />
            </div>}
          </div>
        </Transition>, document.getElementById("overlays")!)}
    </ModalContext.Provider>
  )
}

export default Overlay