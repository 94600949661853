import { ReactNode } from "react"
import { Icon } from "react-feather"

const Button = ({
  children,
  onClick,
  disabled,
  className = "",
}: {
  children: ReactNode
  onClick?: () => void
  disabled?: boolean
  className?: string
}
) => {
  return (
    <button
      className={`
      ${disabled ? "bg-gray-400 cursor-not-allowed" : "bg-primary text-gray-900 hover:shadow-[0px_0px_5px_0_rgba(0,0,0,0.3)]"} bg-noise transition-all duration-300 rounded-md pt-2 pb-2 px-8 text-sm cursor-pointer lowercase inline-flex space-x-2 justify-center items-center font-semibold relative
      
      ${className}
      `}
      onClick={onClick}
      disabled={disabled}
    >{children}</button>
  )
}

export default Button

export const IconButton = ({
  Icon,
  onClick,
  disabled,
  size = 16,
  danger = false
}: {
  Icon: Icon
  onClick?: () => void
  disabled?: boolean
  size?: number
  danger?: boolean
}) => {
  return (
    <button
      className={`aspect-square cursor-pointer p-2 ${danger ? "bg-red-500 text-white" : "bg-primary text-white"} rounded-full shadow transform transition-all duration-300 hover:shadow-[0px_0px_5px_0_rgba(0,0,0,0.3)]`}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon size={size} />
    </button >
  )
}