import { ReactNode } from "react"

const Container = ({
  children,
  size = "medium",
  noBackground = false,
}: {
  children: ReactNode
  size?: "small" | "medium" | "full"
  noBackground?: boolean
}) => {
  return (
    <div className={`mx-auto ${noBackground ? "" : ""} ${size === "small" ? "max-w-screen-sm" : size === "medium" ? "max-w-screen-lg" : "max-w-full"} @container/container`}>
      <div className={`${noBackground ? "py-4" : "px-4 py-4 @5xl/container:px-4"}`}>
        {children}
      </div>
    </div>
  )
}

export default Container