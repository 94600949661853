import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase/firebase";
import { User } from "../types";
import Button from "./Button";
import Input, { InputGroup } from "./Input";

const Signup = ({
  user,
  setUser
}: {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>
}) => {

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  //const [birthday, setBirthday] = useState<Timestamp>()
  const [error, setError] = useState("")
 
  const handleSignup = async () => {
    if (email && password && name && lastName) {
      const auth = getAuth();
      try {
        await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {

          const newUser: User = {
            id: userCredential.user.uid,
            name: name,
            lastName: lastName,
            email: email,
            orders: [],
            cart: user.cart,
          };

          // Add the user to Firestore
          await setDoc(doc(db, "users", userCredential.user.uid), newUser).then(() => {
            setUser(newUser);
          })
        })

      } catch (error: any) {
        const errorMessage = error.message;
        setError(errorMessage);
      }
    } else {
      console.log("Incomplete form data");
    }
  };

  return (
    <div className="flex flex-col gap-2">

      <InputGroup>
        <Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </InputGroup>
      <InputGroup>
        <Input type="text" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <Input type="text" label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </InputGroup>

      <Button onClick={() => handleSignup()}>Sign up</Button>

      <p>{error}</p>
    </div>
  )
}

export default Signup

