import { deleteDoc, doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import { useEffect, useState } from "react"
import { Edit as EditIcon, Trash2, Upload as UploadIcon } from "react-feather"
import { checkIfFileExists, db } from "../firebase/firebase"
import { resizeFile } from "../helpers/imageHelper"
import { Donut, User } from "../types"
import Button, { IconButton } from "./Button"
import DonutImage from "./DonutImage"
import ImageGallery from "./ImageGallery"
import InputSection from "./InputSection"
import Overlay from "./Overlay"
import ProgressBar from "./ProgressBar"

const Edit = ({
  donut,
  user,
}: {
  donut: Donut
  user: User
}) => {
  const [name, setName] = useState(donut.name)
  const [description, setDescription] = useState(donut.description)
  const [price, setPrice] = useState(donut.price)
  const [quantity, setQuantity] = useState(donut.quantity)
  const [image, setImage] = useState<any>()
  const [imageName, setImageName] = useState<string>()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [progress, setProgress] = useState(0);

  const handleEdit = async () => {

    setIsUpdating(true)

    const filePath = `/donuts/${imageName}`

    const exists = await checkIfFileExists(filePath)

    const storage = getStorage();
    const storageRef = ref(storage, filePath);
    if (exists) {
      if (imageName) {
        getDownloadURL(storageRef)
          .then(async url => {
            const ref = doc(db, "donuts", donut.id);
            console.log(url)
            await updateDoc(ref, {
              name: name,
              description: description,
              price: price,
              quantity: quantity,
              imageUrl: url,
              imageName: imageName,
              storageRef: filePath,
            }).then(() => {
              console.log(`Updated ${name}`)
              setIsUpdating(false)
            })
          })
          .catch(error => { console.error(error) })
      } else {
        console.log(image.name, "image name does not exist")
      }
    } else {
      console.log("does not exist")

      if (!image) {
        console.log("No image")
        setIsUpdating(false)
        return
      }
      const storageRefNewImage = ref(storage, `/donuts/${image.name}`)
      const uploadTask = uploadBytesResumable(storageRefNewImage, image)
      uploadTask.on("state_changed", (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        setProgress(percent)
      },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            const ref = doc(db, "donuts", donut.id);
            await updateDoc(ref, {
              name: name,
              description: description,
              price: price,
              quantity: quantity,
              imageUrl: url,
              imageName: image.name,
              storageRef: `/donuts/${image.name}`,
            }).then(() => {
              console.log(`Updated ${name}`)
              setIsUpdating(false)
            })
          });
        }
      );
    }

  }

  const handleNewImage = async (event: any) => {
    try {
      const file = await resizeFile(event.target.files[0], 300);
      setImage(file);
    } catch (err) {
      console.error(err)
    }
  }

  const handleDelete = async () => {
    if (donut) {
      setIsDeleting(true)
      await deleteDoc(doc(db, "donuts", donut.id)).then(() => {
        console.log(`Deleted ${donut.name}`)
        setIsDeleting(false)
      })
    }
  }

  const handleOldImage = () => {

    const storage = getStorage();
    getDownloadURL(ref(storage, donut.storageRef))
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          setImage(xhr.response);
          setImageName(donut.imageName)
        };
        xhr.open('GET', url);
        xhr.send();

      })
      .catch((error) => {
        // Handle any errors
      });
    /* 
    if (FileReader && donut.imageBlob) {
      console.log("has blob ")
      var fr = new FileReader()
      fr.onload = function () {
        setImage(fr.result)
      }
      fr.readAsDataURL(donut.imageBlob)
    } 
    */
  }

  useEffect(() => {
    handleOldImage()
    // eslint-disable-next-line
  }, [])

  return (
    <Overlay title="Edit Donut" trigger={<IconButton Icon={EditIcon} size={11} />} isBusy={isUpdating || isDeleting}>
      <div className="w-full flex gap-4">
        <div className="w-full sm:w-1/3 flex flex-col gap-2">
          {true && <ImageGallery setImage={setImage} setImageName={setImageName} />}
          <label>
            <Button><UploadIcon size={16} /> Upload</Button>
            <input type="file" onChange={handleNewImage} accept="/image/*" className="absolute -left-[9999px]" />
          </label>
          <div className="text-sm font-semibold mb-1">Old Image</div>
          <div className="">
            <DonutImage donut={donut} />
          </div>
          {image && <div>
            <div className="text-sm font-semibold mb-1">New Image</div>
            <img src={URL.createObjectURL(image)} width={image.width} height={image.height} className="w-full max-w-[300px] h-auto rounded" alt="" />
          </div>
          }
        </div>
        <div className="w-full sm:w-2/3 flex flex-col space-y-4">

          <InputSection label="Title">
            <input type="text" name="title" onChange={(e) => setName(e.target.value)} value={name} />
          </InputSection>

          <InputSection label="Price">
            <input type="number" name="price" onChange={(e) => setPrice(Number(e.target.value))} value={price} />
          </InputSection>

          <InputSection label="Quantity">
            <input type="number" name="quantity" onChange={(e) => setQuantity(Number(e.target.value))} value={quantity} />
          </InputSection>

          <InputSection label="Description">
            <textarea name="description" onChange={(e) => setDescription(e.target.value)} value={description}></textarea>
          </InputSection>

          <div className="flex items-center gap-2 w-full">
            <Button onClick={handleEdit} className="flex-1" disabled={!user.isAdmin || isUpdating || !name || !price || !quantity || !imageName}>Update</Button>
            <IconButton onClick={handleDelete} disabled={!user.isAdmin || isDeleting} Icon={Trash2} />
          </div>

          <ProgressBar progress={progress} />

        </div>
      </div>
    </Overlay>
  )
}

export default Edit