import { useState } from "react"
import Signup from "./Signup"
import SignIn from "./SiginIn"
import { User } from "../types"

const Auth = ({
  user,
  setUser
}: {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
}) => {
  const [current, setCurrent] = useState<"login" | "signup">("signup")
  return (
    <div className="">
      <div className="flex border-b border-gray-400 mb-8">
        <Tab onClick={() => setCurrent("login")} label="Login" active={current === "login"} />
        <Tab onClick={() => setCurrent("signup")} label="Sign up" active={current === "signup"} />
      </div>
      {current === "login" ? <SignIn user={user} setUser={setUser} /> : <Signup user={user} setUser={setUser} />}
    </div>
  )
}

export default Auth

const Tab = ({
  label,
  onClick,
  active,
}: {
  label: string,
  onClick: () => void,
  active: boolean
}) => {
  return (
    <div className={`flex-1 p-2 text-center text-sm font-semibold cursor-pointer even:border-l even:border-primary transition-all duration-500 ${active ? "bg-primary" : "hover:bg-primary bg-opacity-50"}`} onClick={onClick}>{label}</div>
  )
}