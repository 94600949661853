import { X, Minus, Plus } from "react-feather";
import { removeFromCart, removeOneFromCart, addOneToCart } from "../helpers/donutHelper";
import { User } from "../types";
import { IconButton } from "./Button";

const Cart = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {
  return (
    <>
      {(!user.cart || !user.cart?.items || user.cart?.items?.length <= 0) && <p className="text-sm">Your Cart is empty.</p>}
      <div className="flex flex-col gap-2">
        {user.cart?.items?.map(item => {
          return (
            <div key={item.donut.id} className="flex gap-4 items-center justify-between text-sm border-b last:border-b-0 border-gray-300 pb-2">
              <div className="truncate">
                {item.donut.name} <span className="text-gray-500">({item.donut.quantity})</span>
              </div>
              <div className="flex gap-1 items-center">
                <IconButton Icon={X} size={10} danger onClick={() => removeFromCart(item.donut, user, setUser)} />
                <IconButton Icon={Minus} size={10} onClick={() => removeOneFromCart(item.donut, user, setUser)} />
                <div className=" w-7 flex items-center justify-center">{item.quantity}</div>
                <IconButton Icon={Plus} size={10} onClick={() => addOneToCart(item.donut, user, setUser)} />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Cart