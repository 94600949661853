import { useState } from "react";
import Cart from "../components/Cart";
import Container from "../components/Container"
import Input, { InputGroup } from "../components/Input";
import { Order, User, defaultCart } from "../types";
import Button from "../components/Button";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { createId } from "../helpers/stringHelper";
import { db } from "../firebase/firebase";
import { Loading } from "../components/Loading";

const Checkout = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {

  const [name, setName] = useState(user.name)
  const [lastName, setLastName] = useState(user.name)
  const [email, setEmail] = useState(user.email || "")
  const [phone, setPhone] = useState(user.phone || "")

  const [isBusy, setIsBusy] = useState(false)

  const cantOrder = !(name && lastName && email && phone) || user.cart.items.length === 0 || isBusy

  const checkout = async () => {
    setIsBusy(true)
    const id = createId()

    const uploadOrder: Order = {
      id: id,
      userId: user.id,
      userName: user.name,
      email: email,
      phone: phone,
      date: Timestamp.now(),
      cart: user.cart
    }

    await setDoc(doc(db, `orders/${id}`), uploadOrder).then(() => {
      setUser(ou => ({...ou, cart: defaultCart}))
      setIsBusy(false)
    })
  }

  return (
    <Container>
      <div className="flex gap-4">
        <div className="flex-1 flex flex-col gap-4">
          <h1>Checkout</h1>
          <InputGroup>
            <Input label="Name" value={name} onChange={e => setName(e.target.value)} />
            <Input label="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
          </InputGroup>
          <InputGroup>
            <Input label="Email" value={email} onChange={e => setEmail(e.target.value)} type="mail" />
            <Input label="Phone" value={phone} onChange={e => setPhone(e.target.value)} type="tel" />
          </InputGroup>
        </div>
        <div className="border-l border-dotted border-gray-400 pl-4 flex flex-col justify-between">
          <Cart user={user} setUser={setUser} />
          <Button disabled={cantOrder} className="w-full mt-4" onClick={() => checkout()}>Order</Button>
        </div>
      </div>
      {isBusy && <Loading />}
    </Container>
  )
}

export default Checkout