import { Donut, User, defaultCart } from "../types";

export const removeFromCart = (donut: Donut, user: User, setUser: React.Dispatch<React.SetStateAction<User>>) => {
  const u = { ...user };
  u.cart = u.cart || defaultCart;

  const index = u.cart.items.findIndex((d) => d.donut.id === donut.id);

  if (index !== -1) {
    u.cart.items.splice(index, 1);
  }

  setUser(u);
}

export const removeOneFromCart = (donut: Donut, user: User, setUser: React.Dispatch<React.SetStateAction<User>>) => {
  const u = { ...user };
  u.cart = u.cart || defaultCart;

  const isInCart = u.cart.items.some(i => i.donut.id === donut.id);

  if (isInCart) {
    const updateItem = u.cart.items.find(i => i.donut.id === donut.id);
    if (updateItem) {
      if (updateItem.quantity > 1) updateItem.quantity -= 1;
      else u.cart.items = u.cart.items.filter(d => d.donut.id !== donut.id);
    }
    setUser(u);
  }
}

export const addOneToCart = (donut: Donut, user: User, setUser: React.Dispatch<React.SetStateAction<User>>) => {
  const u = { ...user };
  u.cart = u.cart || defaultCart;

  const isInCart = u.cart.items.some(i => i.donut.id === donut.id);

  if (isInCart) {
    const updateItem = u.cart.items.find(i => i.donut.id === donut.id);

    if (updateItem) {
      updateItem.quantity += 1;
    }
  } else {
    u.cart.items.push({
      donut: donut,
      quantity: 1,
    });
  }

  setUser(u);
}

export const getDonutAmount = (donut: Donut, user: User): number => {
  const u = { ...user };

  // Check if user.cart is defined
  if (u.cart && Array.isArray(u.cart.items)) {
    const index = u.cart.items.findIndex((d) => d.donut.id === donut.id);

    // Check if the index is valid
    if (index !== -1 && u.cart.items[index] && typeof u.cart.items[index].quantity !== 'undefined') {
      const quantity = u.cart.items[index].quantity;
      return quantity || 0;
    }
  }

  // Default return value if user.cart is not defined or item not found
  return 0;
};

export const getBoxesInCartCount = (user: User): number => {
  const u = { ...user }
  const hasCart = u.cart !== undefined
  const cartNotEmpty = u.cart?.items?.length > 0
  let count = 0
  if (hasCart && cartNotEmpty) {
    u.cart.items.forEach(item => {
      count += item.quantity
    })
  }
  return count
}