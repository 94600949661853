
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCac76Vh0c60H2idiDkT9osAb5D6ydaa00",
  authDomain: "donutello-cf8df.firebaseapp.com",
  databaseURL: "https://donutello-cf8df.firebaseio.com",
  projectId: "donutello-cf8df",
  storageBucket: "donutello-cf8df.appspot.com",
  messagingSenderId: "960963006554",
  appId: "1:960963006554:web:cb4dab47cd9b5b58950863"
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);

export const storage = getStorage(firebase);

export const checkIfFileExists = (filePath: string): Promise<boolean> => {
  const storage = getStorage();
  const storageRef = ref(storage, filePath);

  return getDownloadURL(storageRef)
    .then(url => {
      return Promise.resolve(true);
    })
    .catch(error => {
      if (error.code === 'storage/object-not-found') {
        return Promise.resolve(false);
      } else {
        return Promise.reject(error);
      }
    });
}