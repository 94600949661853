import { Clipboard, Mail, Phone, User } from "react-feather";
import { formattedDateFullWithTime } from "../helpers/dateHelper";
import { Order } from "../types";

const OrderItem = ({ order }: { order: Order }) => {

  const copy = (copyText: string) => {
    navigator.clipboard.writeText(copyText)
    //setCopied(true)
  }
  return (
    <div key={order.id} className="
    last:border-b-0 p-2 bg-white rounded relative overflow-hidden
    before:content-[''] before:w-0.5 before:absolute before:top-0 before:left-0 before:bottom-0 before:h-full before:bg-primary
    ">
      <div className="flex justify-between flex-col sm:flex-row">

        <div className="text-sm text-gray-600 order-2 sm:order-1">
          <p onClick={() => copy(order.id)} className="flex gap-1 items-center cursor-pointer">
            <span className="text-primary">Order ID: </span><span>{order.id}</span>
            <Clipboard size={14} />
          </p>
          <p onClick={() => copy(order.userId)} className="flex gap-1 items-center cursor-pointer">
            <span className="text-primary">User ID: </span><span>{order.userId}</span>
            <Clipboard size={14} />
          </p>
          <p className="flex items-center gap-1"><User size={16} className="text-primary" />{order.userName}</p>
          <p className="flex items-center gap-1"><Mail size={16} className="text-primary" />{order.email}</p>
          <p className="flex items-center gap-1"><Phone size={16} className="text-primary" />{order.phone}</p>
        </div>

        <div className="sm:text-right order-1 sm:order-2 mb-2 sm:mb-0">
          <p className="text-sm text-gray-600">{formattedDateFullWithTime(order.date.seconds * 1000)}</p>

        </div>

      </div>
      <div className="pt-1 mt-1 border-t border-gray-300">
        {order.cart.items?.map((donut, i) => {
          return (
            <div className="" key={i}>
              {donut.quantity}x {donut.donut.name} <span className="text-sm text-gray-600">({donut.donut.quantity})</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OrderItem