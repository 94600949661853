import { Timestamp } from "firebase/firestore"
import { createId } from "./helpers/stringHelper"

export interface Donut {
  id: string
  imageUrl: string,
  imageName: string,
  storageRef: string,
  lastModified: Timestamp,
  name: string,
  description: string,
  available: boolean,
  price: number,
  quantity: number,
  fileName: string,
}

export interface User {
  id: string
  name: string
  lastName: string
  email?: string
  phone?: string
  orders: Order[]
  cart: Cart
  isAdmin?: boolean
}

export const defaultCart: Cart = Object.freeze({
  id: createId(),
  items: [],
})

export const defaultUser: User = {
  id: createId(),
  name: "",
  lastName: "",
  email: undefined,
  orders: [],
  cart: defaultCart,
}

export interface Cart {
  id: string
  items: OrderItem[]
}

export interface OrderItem {
  donut: Donut
  quantity: number
}

export interface Order {
  id: string
  userId: string
  userName: string
  email: string
  phone: string
  date: Timestamp
  cart: Cart
}