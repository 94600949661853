import { getAuth, User as FirebaseUser } from "firebase/auth"
import { ReactNode, useEffect, useRef, useState } from "react"
import { Icon, LogOut, Settings, User as UserIcon } from "react-feather"
import { Link } from "react-router-dom"
import Logo from "../assets/logo.png"
import { logOut } from "../helpers/userHelper"
import { User } from "../types"
import Auth from "./Auth"
import CartPopOver from "./CartPopOver"
import Overlay from "./Overlay"

const Header = ({
  children,
  user,
  setUser,
}: {
  children?: ReactNode
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
}) => {
  const [current, setCurrent] = useState<string>(window.location.pathname)
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null)
  const headerRef = useRef<HTMLElement>(null)
  const auth = getAuth();

  auth.onAuthStateChanged(cu => {
    setCurrentUser(cu)
  })

  useEffect(() => {
    const body = document.getElementById('root')
    if (headerRef?.current) {
      const headerHeight = headerRef.current.clientHeight
      if (body) body.style.paddingTop = headerHeight + 16 + "px"
    }
    setCurrent(window.location.pathname)
  }, [])


  const handleLogout = async () => {
    console.log("logging out")
    await logOut();
  };

  return (
    <header ref={headerRef} className="fixed top-0 left-0 right-0 z-30 flex justify-center p-2 pb-4 bg-[url('../assets/wave.svg')] bg-auto bg-repeat-x">
      <div className="flex justify-center max-w-screen-lg w-full px-4 rounded">
        <div className=" max-w-5xl w-full flex items-center gap-4">
          <img src={Logo} alt="Donutello Donuts" className="h-8 w-auto" />
          <div className="flex gap-4 flex-1 items-center">
            <HeaderItem url="/" text="Home" current={current} />
            <HeaderItem url="/donuts" text="Donuts" current={current} />
          </div>

          {currentUser && user && (
            <div className="flex gap-4 items-center justify-start">
              <CartPopOver user={user} setUser={setUser} />
              {currentUser && <HeaderIcon Icon={UserIcon} url="/user" current={current} />}
              {currentUser && user && user.isAdmin && <HeaderIcon Icon={Settings} url="/admin" current={current} />}
              {!currentUser ?
                <Overlay trigger={<div className=""><LogOut size={18} className="cursor-pointertext-text" /></div>}>
                  <Auth user={user} setUser={setUser} />
                </Overlay>
                :
                <LogOut size={18} onClick={handleLogout} className="text-text" />
              }
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header

const HeaderItem = ({
  url,
  text,
  current,
}: {
  url: string
  text: string
  current: string
}) => {
  //console.log(current, url, current === url)
  return (
    <Link to={url}>
      <div className={`py-2 text-text border-b-2 ${current === "url" ? "border-text bg-blue-400" : "border-transparent"}`}>
        {text}
      </div>
    </Link>
  )
}


const HeaderIcon = ({
  url,
  Icon,
  current,
  onClick,
}: {
  Icon: Icon
  current: string
  url?: string
  onClick?: () => void
}) => {
  const icon = <Icon size={18} className={`text-white`} />
  return (
    url ? <Link to={url}>
      {icon}
    </Link>
      :
      <div className="" onClick={onClick}>{icon}</div>
  )
}
