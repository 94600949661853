import { ChevronLeft } from "react-feather"
import { Link } from "react-router-dom"
import Logo from "../assets/logo.png"

const NotFound = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex flex-col items-center justify-center">
        <img src={Logo} alt="Donutello Donuts Logo" className="w-24 h-24" />
        <h1>404</h1>
        <p>Oops, this site does not exist</p>
        <Link to="/" className="flex items-center gap-2"><ChevronLeft size={16} /> Back to home</Link>
      </div>
    </div>
  )
}

export default NotFound