import { useState } from "react"
import Logo from "../assets/logo.png"
import { Donut } from "../types"
import { Spinner } from "./Loading"

const DonutImage = ({ donut }: { donut: Donut }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [imageError, setImageError] = useState(false)
  const imageLoaded = () => setIsLoading(false)

  return (
    <div className="flex items-center justify-center relative w-full h-full flex-shrink-0">
      {isLoading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Spinner size="big" />
        </div>
      )}
      <img
        src={imageError ? Logo : donut.imageUrl}
        alt={donut.name}
        className={`object-contain overflow-hidden ${isLoading ? "hidden" : ""} ${imageError ? "w-16 h-auto" : "w-full h-full"}`}
        onLoad={() => imageLoaded()}
        onError={() => setImageError(true)}
      />
    </div>
  )
}

export default DonutImage