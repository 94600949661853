import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Minus, Plus } from "react-feather";
import { IconButton } from "../components/Button";
import Container from "../components/Container";
import DonutImage from "../components/DonutImage";
import Edit from "../components/Edit";
import { Loading } from "../components/Loading";
import Upload from "../components/Upload";
import { db } from "../firebase/firebase";
import { addOneToCart, getDonutAmount, removeOneFromCart } from "../helpers/donutHelper";
import { Donut, User } from "../types";

const Donuts = ({ user, setUser }: { user: User; setUser: React.Dispatch<React.SetStateAction<User>> }) => {

  const [donuts, setDonuts] = useState<Donut[]>([])
  const [fetchedAll, setFetchedAll] = useState(false)

  const getDonuts = async () => {
    const q = query(collection(db, "donuts"))

    onSnapshot(q, (querySnapshot) => {
      const d: Donut[] = []
      querySnapshot.forEach((doc) => {
        let donutSnapshot = doc.data() as Donut
        donutSnapshot.id = doc.id
        d.push(donutSnapshot)
      })
      setDonuts(d)
      setFetchedAll(true)
    })
  }

  useEffect(() => {
    getDonuts()
  }, [])

  return (
    <>
      <Container>
        <div className="@container/donuts">
          <div className="grid grid-cols-2 @lg/donuts:grid-cols-3 @3xl/donuts:grid-cols-5 gap-2">
            {donuts.map(donut => {
              return (
                <div key={donut.id} className="group w-full bg-surface border border-white transition-shadow duration-500 rounded-lg flex flex-col relative mt-16">
                  <div className="-mt-16 group-hover:scale-105 transoform transition-all duration-300 w-full h-full px-1 aspect-square group-hover:drop-shadow-lg">
                    <DonutImage donut={donut} />
                  </div>

                  <div className="absolute top-2 right-2 flex gap-2">
                    {user?.isAdmin && <Edit donut={donut} user={user} />}
                  </div>
                  <div className="p-2">
                    <h3 className="text-center font-semibold">{donut.name}</h3>

                    <div className="flex gap-1 items-center justify-between border-t border-gray-300 pt-2 mt-2">
                      {donut.price && <div className="text-gray-600 text-center">RM{donut.price}</div>}
                      <div className="flex items-center">
                        <IconButton Icon={Minus} size={11} onClick={() => removeOneFromCart(donut, user, setUser)} />
                        <div className=" w-7 flex items-center justify-center">{getDonutAmount(donut, user)}</div>
                        <IconButton Icon={Plus} size={11} onClick={() => addOneToCart(donut, user, setUser)} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            {user && user.isAdmin && <Upload user={user} />}

          </div>
        </div>
      </Container>

      {!fetchedAll && <Loading size="big" />}
    </>
  )
}

export default Donuts